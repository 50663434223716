/* Style for all HTML files */
nav {
    height: 11vh;
}

.nav-text-size {
    font-size: medium;
}

.bg-primary{
    background-color: #76CEF2 !important;
}

.bg-secondary{
    background-color: #E0ADCE !important;
}

.bg-third{
    background-color: #F0FF88 !important;
}

.text-primary{
    color: #76CEF2 !important;
}
.text-secondary{
    color:#E0ADCE !important;
}
.text-third{
    color:#F0FF88 !important;
}

.btn-primary{
    background-color: #76CEF2 !important;

}
.btn-secondary{
    background-color: #E0ADCE !important;
}
.btn-third{
    background-color: #F0FF88 !important;

}

.btn{
    text-transform: capitalize;
}

.insta-size{
    font-size: 30px;
}

.bg-img {
    background-size: cover;
    background-position: center center;
}

#logo {
    height: 9vh;
}

@media (max-width: 600px) {
    #navbarButtonsExample { 
        height: 81vh;
    }

    nav {
        height: 9vh;
    }

    #logo {
        height: 7vh;
    }

    .nav-text-size {
        font-size: larger;
    }

    #nav-btn-instagram {
        width: 75%;
    }
}
